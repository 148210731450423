var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Model, Component, Ref } from 'vue-property-decorator';
import { ImageFileType, getDefaultPrepSettings } from '@ht-lib/accounts-models';
import ImagePrepPresetModal from './ImagePrepPresetModal.vue';
import ImagePrepInputs from './ImagePrepInputs.vue';
import { cloneDeep, merge } from 'lodash';
import { ImagePrepPreset } from '@ht-web/firebase-adapter';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.dpi = 0;
        this.fileTypeOptions = Object.values(ImageFileType);
        this.showAdvanced = false;
        this.presets = [];
        this.activePreset = null;
        this.loading = true;
    }
    changePreset(c) {
        console.log(c);
        this.activePreset = c;
        if (c) {
            this.$emit('input', cloneDeep(c.settings));
        }
        else {
            this.$emit('input', getDefaultPrepSettings());
        }
    }
    update(c) {
        this.$emit('input', cloneDeep(merge(this.prep, c)));
    }
    editPreset() {
        this.presetModal.showModal(this.activePreset);
    }
    addPreset() {
        this.presetModal.showModal(null);
    }
    beforeDestroy() {
        this.snap();
    }
    created() {
        this.snap = ImagePrepPreset.listen((data) => {
            this.presets = data.sort((a, b) => a.name.localeCompare(b.name));
            this.loading = false;
        });
    }
};
__decorate([
    Model('input')
], default_1.prototype, "prep", void 0);
__decorate([
    Ref()
], default_1.prototype, "presetModal", void 0);
default_1 = __decorate([
    Component({
        name: 'ImagePrepSettingsComp',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        components: { ImagePrepPresetModal, ImagePrepInputs }
    })
], default_1);
export default default_1;
